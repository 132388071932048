.location-ad-slot-top-small {
    min-height: 50px !important;
    min-width: 160px !important;
    max-height: 100px !important;
    max-width: 320px !important;
    padding: 10px;
}

.location-ad-slot-top-small-mobile {
    width: 100%;
    height: 100%;
    padding: 10px;
}

.location-ad-slot-top:hover {
    cursor: pointer;
}
.location-ad-slot-top-large {
    min-height: 140px !important;
    min-width: 168px !important;
    max-height: 280px !important;
    max-width: 336px !important;
    padding: 10px;
}

.location-ad-slot-top-large-mobile {
    width: 100%;
    height: 100%;
    padding: 10px;
}

.loaction-ad-slots-top-contaner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}