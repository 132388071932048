.login-title {
  padding-top: 20px;
  padding-bottom: 10px !important;
  font-size: 26px;
  color: black;
  margin-bottom: 0px !important;
  font-weight: bold;
  p {
    font-weight: 500;
  }
}

.login-button {
  background-color: #fd2dbf;
  font-weight: 600;
  margin-top: 20px;
}

.extra-links-sign-up {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0 1px;
}

.create-account,
.forgot-password {
  padding: 0;
  margin: 0;
  color: #000000;
  //   background-color: #ffffff;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
}

.tnc-modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-errors {
  font-size: smaller;
  color: red;
}

.form-description {
  font-size: 12px;
  font-weight: 500;
}

.tnc-modal .modal-content {
  background-color: #fff; /* Ensure the background is white (or any color you want) */
  border-radius: 8px; /* Optional for rounded corners */
}

.tnc-modal .modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5); /* Set a semi-transparent backdrop */
}


.tnc-modal .modal-dialog {
  max-height: 80vh; /* Set a fixed height for the modal relative to viewport height */
  display: flex;
  flex-direction: column;
}

.tnc-modal .modal-content {
  flex-grow: 1;
  overflow: hidden; /* Prevent overflow beyond the modal content */
}

.tnc-modal .modal-body {
  overflow-y: auto; /* Make the modal body scrollable */
  max-height: calc(100vh - 160px); /* Adjust height to fit the modal */
  padding: 20px;
}
