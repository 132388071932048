.cam-thumbnail-container {
  padding-left: 5px;
  padding-right: 5px;
  min-width: 300px !important;
  max-width: 300px;
}
.cam-thumbnail-img {
  width: 100%;
  height: 100%;
  // border-bottom: 1px solid;
  border-color: darkgray;
  box-shadow: none !important;
  border-radius: 0px !important;
  display: block;
}

.cam-thumbnail-name {
  color: #fd2dbf;
  padding-top: 5px;
}

.cam-thumbnail-img:hover {
  cursor: pointer;
}

.live-cam-thumbnail {
  position: relative;
}

.cam-thumbnail-icon {
  position: absolute;
  left: 9px;
  top: 9px;
}

.cam-play-button-icon {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%; 
  transform: translate(-50%, -50%); 
}