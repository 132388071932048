.account-title {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 26px;
  color: black;
  font-weight: bold;
  p {
    font-weight: 500;
  }
}
.account-label {
  font-weight: bold;
  color: gray;
}
.account-split-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .get-premium-button {
    background-color: rgb(87, 86, 86);
    border-radius: 1px;
    width: 100%;
    font-size: 15px;
    font-weight: bolder;
  }
}
.account-label-italic {
  font-style: italic;
  font-size: small;
  color: gray;
}
.manage-subscription-button {
  background-color: #fd2dbf;
  font-weight: 600;
  margin-top: 20px;
}
.account-sign-out-button {
  background-color: gray;
  font-weight: 600;
  margin-top: 20px;
}
.email-not-verified-link-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 0 1px;
}

.resend-email {
  padding: 0;
  margin: 0;
  color: #000000;
  //   background-color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}

.tnc-modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-errors {
  font-size: smaller;
  color: red;
}

.generic-account-ad-slot-container {
  width: 100%;

  .generic-account-ad-slot {
    width: 100%;
    height: auto;
  }
}

.account-name-row {
  display: flex;
  flex-direction: row;
}
