.home-cams-container {
  height: 225px !important;
  //   min-width: 1300px !important;
  overflow-x: scroll !important;
  display: flex;
  flex-direction: row;
  justify-content: left;
  background-color: #fafafa;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 40px;
}
