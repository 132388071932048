.generic-ad-slot {
    min-height: 100px;
    min-width: 320px;
    // max-width: 500px;
    transition: none !important;
    box-shadow: none !important;
}

.generic-ad-slot:hover {
    transition: none !important;
}

.generic-ad-slot:hover {
    cursor: pointer;
}

.generic-ad-slot-container {
    // padding-top: 30px;
    // padding-bottom: 30px;
    width: 100%;
    img:hover{
        transition: none !important;
    }
}

.generic-ad-slot-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}