.forcast-home {
  padding: 0px 10px 10px 10px;
  .img:hover {
    transform: none !important;
    cursor: pointer;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  .pwa-banner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.main-post {
  position: relative;
  width: 100%;
  > .main-post-overlay {
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 2;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 0px 0px 0.25em 0.25em;
  }
}

.post-card {
  padding: 10px;
}

.home-other-posts {
  padding: 0px 10px 10px 10px;
  margin: 0px 10px 10px 10px;
}

.load-more {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: #2c2c2c;
  padding-top: 2px;
}
.load-more:hover {
  cursor: pointer;
}

.post-date {
  font-size: smaller;
  color: black !important;
}

.main-post-date {
  font-size: smaller;
}

.home-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px !important;
  margin-bottom: 10px !important;
  .long-description {
    p {
      color: #9a9a9a !important;
      text-align: center !important;
      font-size: 1.5em !important;
      font-weight: 500 !important;
    }
    a {
      color: #fd2dbf !important;
      font-weight: 600 !important;
    }
  }
}
