.wind-chart-container {
    min-width: 1300px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: white;
    height: 174px;
}

.chart-time-line {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #231F20;
    font-weight: 500;
    font-size: 14px;
    padding-top: 6px;
    padding-bottom: 6px;
}