.wave-height-chart{
    min-width: 1300px;
    min-height: 280px;
}

.chartTitle {
    font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 18px;
    color: '#55555';
}