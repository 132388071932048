.long-description {
  text-align: left;
  img {
    box-shadow: none !important;
    border-radius: 0px !important;
  }
  img:hover {
    transform: none !important;
  }
  p {
    color: #231f20 !important;
    font-weight: 500;
    line-height: 1.5em;
    font-size: 14px !important;
    margin: 0px !important;
  }
  a {
    color: #fd2dbf !important;
    font-weight: 600;
  }
  .gsn-hash-link {
    color: #fd2dbf !important;
    font-weight: 600;
  }
}
