.speed-direction-trans{
  position: absolute;
  .speed-direction-arrow {
    position: relative;
    top: -34px;
    left: 7px
  }
}
#badge-ribbon {
    position: relative;
    background:white;
    height: 35px;
    width: 35px;
    border-radius: 18px;
    top: 10px;
  }
.wave-period-label {
  font-size: 10px;
}
// #badge-ribbon:before,
// #badge-ribbon:after {
//     content: '';
//     position: absolute;
//     border-bottom: 12px solid #6610f2;
//     border-left: 6px solid transparent;
//     border-right: 6px solid transparent;
//     top: -5px;
//     left: 10px;
//     transform: rotate(180deg);
//     opacity: 1 !important;
// }