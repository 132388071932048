.install-pwa-logo-img {
  background-color: transparent;
  height: 50px;
  width: 50px !important;
  box-shadow: none !important;
  margin: 5px !important;
}

.install-pwa-banner {
  // padding-left: 10px !important;
  // padding-right: 10px !important;
  position: relative;
  h3 {
    color: #fd2dbf !important;
    font-weight: 600 !important;
    margin-bottom: 0px !important;
  }
}
.pwa-banner-image {
  box-shadow: none !important;
  border-radius: 0px !important;
}

.pwa-banner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.pwa-banner:hover {
  cursor: pointer;
}

.pwa-close-button {
  position: absolute;
  right: 14px;
  top: 4px;
  z-index: 100;
}

.pwa-close-button:hover {
  cursor: pointer;
}

.pwa-banner-text {
  padding-right: 40px;
}
