.tidesContainer {
    min-width: 1300px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
    background-color: white;
}

.tidesTable {
    border-bottom: 1px solid lightgray;
}

.hiText {
    // @media (min-width: 1200px) {
    //     font-size: 12px;
    // }
    font-size: 12px;
    color: #1A237E;
    margin:0px;
    font-weight: bold;
}
.lawText {
    // @media (min-width: 1200px) {
    //     font-size: 12px;
    // }
    color: #2196F3;
    font-size: 12px;
    margin:0px;
    font-weight: bold;
}
.tide-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ampm {
    font-size: 8px;
}
.tideTooltip {
    border-radius: 2px !important;
    margin: 0px !important;
    padding: 5px !important;
    font-size: 13px !important;
}

.chartTitle {
    font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 18px;
    color: '#55555';
}

.test {
    background-color: lightcoral;
}