.footer-link-button {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
    padding-right: 20px;
    margin: 0px !important;
}

.footer-link-button:hover {
    cursor: pointer;
}
.footer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}