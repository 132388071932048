.install-pwa-logo-img {
    border-top-right-radius: '50%';
    border-top-left-radius: '50%';
    background-color: '#fff';
    margin-top: '-50px';
    left: '50%';
}

.install-pwa-logo-div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 5px;
}

