.free-trial-slot {
  min-height: 100px;
  min-width: 320px;
  // max-width: 500px;
  transition: none !important;
  box-shadow: none !important;
}

.free-trial-slot:hover {
  transition: none !important;
}

.free-trial-slot:hover {
  cursor: pointer;
}

.free-trial-slot-container {
  // padding-top: 30px;
  // padding-bottom: 30px;
  width: 100%;
  img:hover {
    transition: none !important;
  }
}

.free-trial-slot-center {
  //   padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
