.promotion-container {
  height: 60px;
  background-color: black;
}
.promotion-text-row {
  height: 60px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.promotion-text {
  margin-bottom: 0px;
  padding-left: 5px;
}

.promotion-text-value {
  text-transform: lowercase;
}

.promotion-link {
  color: #fd2dbf;
  font-style: italic;
  font-size: 20px;
}

.promotion-text:hover {
  cursor: pointer;
}

.promotion-img {
  box-shadow: none !important;
  border-radius: 0px !important;
}
