.fly-og-promotion-container {
    // background-color: #c3996cff;
    background-color: black;
    color: white;
    // height: 230px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
  }
  
  .fly-gsn-premium-image {
    border-radius:0% !important;
    transition: none !important;
    box-shadow: none !important;
  }
  
  .fly-og-hookup-big {
    color: #c3996cff;
    font-size: 24px;
    font-weight: bold;
  }
  
  .fly-be-first-text {
    font-weight: bold;
    font-size: 12px;
  }
  
  .fly-fifty-percent {
    font-size: 24px;
  }
  
  .fly-life-forty {
    font-size: 24px;
    color: white;
  }
  
  .fly-use-code {
    color: #c3996cff;
    font-weight: bold;
  }
  
  .fly-premium-highlights {
    font-weight: bold;
  }
  
  .fly-premium-prices {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .fly-premium-price-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  
  .fly-premium-cross {
    padding-right: 5px;
    text-decoration: line-through;
    // font-weight: 500;
  }
  
  .fly-tnc-apply {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 12px;
  }
  
  .fly-cancel-anytime {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    font-size: 10px;
    font-weight: bold;
    color: gray;
  }
  
  .fly-highlights-list {
    position: relative;
    padding-left: 10px;
    font-weight: bold;
  }

  .fly-package-price-button {
    margin-top: 10px;
    font-size: 28px;
    padding: 10px 20px;
    background-color: #fd2dbf;
    color: white;
    font-weight: 600;
  }

  .fly-package-price-button:hover {
    cursor: pointer;
  }

.fly-auto-renewal {
  color: gray;
  text-align: center;
  font-size: 12px;
  margin-bottom: 20px;
  font-weight: 500;
}
  .og-modal {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .og-modal .modal-content {
    background-color: #fff; /* Ensure the background is white (or any color you want) */
    border-radius: 8px; /* Optional for rounded corners */
  }
  
  .og-modal .modal-backdrop {
    background-color: rgba(0, 0, 0, 0.5); /* Set a semi-transparent backdrop */
  }
  
  
  .og-modal .modal-dialog {
    max-height: 80vh; /* Set a fixed height for the modal relative to viewport height */
    display: flex;
    flex-direction: column;
  }
  
  .og-modal .modal-content {
    flex-grow: 1;
    overflow: hidden; /* Prevent overflow beyond the modal content */
  }
  
  .og-modal .modal-body {
    overflow-y: auto; /* Make the modal body scrollable */
    max-height: calc(100vh - 160px); /* Adjust height to fit the modal */
    padding: 20px;
  }

  .og-modal .modal-content .modal-header {
    background-color: black;
    padding-top: 10px;
  }

  .og-modal .modal-content .modal-header button {
    right: 10px;
    top: 5px;

  }

  .og-modal .modal-header .close {
    color: #fd2dbf;
    text-shadow: none;
    font-size: 36px;
    opacity: 1 !important;
}
