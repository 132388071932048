.gsn-post-loader {
    position: relative;
    width: 100%;
    display: inline-block;
    vertical-align: top;
    >.gsn-post-loader {
        margin: auto;
        width: 68.3px;
        height: 68.3px;
        border: solid transparent;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0
    }
}