.icon-circle {
  border-radius: 50%;
  //   border: 1px solid black;
  height: 32px;
  width: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: black;
  margin-left: 5px;
}

.icon-circle:hover {
  cursor: pointer;
}

.tooltip-container-class {
  //   background-color: black;
  color: black;
}
