.cam {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    .cam-overlay {
        position: absolute;
        bottom: 0px;
        left: 0px;
        z-index: 2;
        background: rgba(0,0,0,.3);
        padding-left: 5px;
        padding-top: 5px;
        width: 100%;
        text-align: center;
    }
    .cam-img {
        box-shadow: none !important;
        border-radius: 0px!important;
    }
    .cam-img:hover {
        transform: none !important;
        cursor: pointer;
    }
    a {
        color: white;
    }
}

