.post-card {
    padding: 10px;
    .img:hover {
        transform: none !important;
        cursor: pointer;
    }
}

.post-date {
    font-size: smaller;
}