.login-title {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 26px;
  color: black;
  font-weight: bold;
  p {
    font-weight: 500;
  }
}

.login-button {
  background-color: #fd2dbf;
  font-weight: 600;
  margin-top: 20px;
}

.extra-links-sign-in {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 0 1px;
}

.create-account,
.forgot-password {
  padding: 0;
  margin: 0;
  color: #000000;
  //   background-color: #ffffff;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
}
