.desktop-icon,
.good-surf-menu:hover {
  cursor: pointer;
}

.good-surf-nav-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  align-items: center;
  width: 100%;
}

.gsn-nav-icon {
  height: 35px;
  // width: 35px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.gsn-nav-icon:hover {
  cursor: pointer;
}

.free-trial-button-nav {
  background-color: #fd2dbf;
  color: #fff;
  padding: 5px 10px;
  border: none;
  font-weight: bold;
  text-transform: capitalize;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}
.avatar-dropdown-text {
  font-style: italic;
  font-size: large !important;
  color: white;
  font-weight: bold !important;
  padding-right: 10px;
}
.avatar-dropdown-menu {
  background-color: #fff !important;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15) !important;
  margin-top: -10px;
}
.avatar-dropdown-item {
  margin-bottom: -10px;
}
.avatar-dropdown-item:hover {
  color: #212529 !important;
}
.free-trial-button-nav:hover {
  background-color: #fd2dbf;
  cursor: pointer;
}

.login-button-nav {
  color: black;
  background-color: transparent;
  padding: 5px 2px;
  border: none;
  font-weight: bold;
  text-transform: capitalize;
  cursor: pointer;
  font-size: 16px;
}

.login-button-nav:hover {
  cursor: pointer;
  background-color: transparent;
}

.desktop-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.desktop-dropdown-icon {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.my-acc-dropdown-text {
  font-style: italic;
  color: grey;
  font-size: 13px;
}

.avatar-dropdown-premium-item {
  color: #fd2dbf;
  font-weight: bolder;
}

.gsn-nav-icon-button {
  height: 35px;
  // width: 35px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
} 
.gsn-auth-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}