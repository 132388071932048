.contact-us-title {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 26px;
    color: black;
    font-weight: bold;
    p {
        font-weight: 500;
    }      
}
.submit-disabled {
    background-color: lightgray !important;
}

.contact-form-email {
    padding: 10px 10px 10px 10px !important;
}