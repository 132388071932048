.premium-og-container {
    background-color: black;
}

.home-og-promotion-container {
    // background-color: #c3996cff;
    background-color: black;
    color: white;
    height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .home-gsn-premium-image {
    border-radius:0% !important;
    transition: none !important;
    box-shadow: none !important;
  }
  
  .home-og-hookup-big {
    color: #c3996cff;
    font-size: 24px;
    font-weight: bold;
  }
  
  .home-be-first-text {
    font-weight: bold;
    font-size: 12px;
  }
  
  .home-fifty-percent {
    font-size: 24px;
  }
  
  .home-life-forty {
    font-size: 24px;
    color: white;
  }
  
  .home-use-code {
    color: #c3996cff;
    font-weight: bold;
  }
  
  .home-premium-highlights {
    font-weight: bold;
  }
  
  .home-premium-prices {
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .home-premium-price-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  
  .home-premium-cross {
    padding-right: 5px;
    text-decoration: line-through;
    // font-weight: bold;
  }
  
  .home-tnc-apply {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 12px;
  }
  
  .home-cancel-anytime {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    font-size: 10px;
    font-weight: bold;
    color: gray;
  }
  
  .home-highlights-list {
    position: relative;
    padding-left: 10px;
    font-weight: bold;
  }

  .home-package-price-button {
    margin-top: 10px;
    font-size: 18px;
    padding: 10px 20px;
    background-color: #fd2dbf;
    color: white;
    font-weight: 600;
  }

  .home-package-price-button:hover {
    cursor: pointer;
  }