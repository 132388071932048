.chart-container {
    overflow-x: scroll;
    padding-bottom: 10px;
}

.chart-headers {
    display: flex;
    justify-content: center;
    background-color: white;
    // padding-top: 5px;
    padding-bottom: 5px;
    .chart-cols {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-bottom: 20px;
        // padding-top: 10px;
    }
    .chart-label {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .chart-7-day-cols {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .chart-7-day-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h4 {
            color: #9A9A9A;
            margin-top: 20px !important;
            margin-bottom: 10px !important;
        }
    }
    .chart-legend {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
            box-shadow: none !important;
            border-radius: 0px!important;
        }
        img:hover {
            transform: none !important;
        }
        .legend-image-desktop {
            width: 200px !important;
        }
        .legend-image-mobile {
            width: 100px !important;
        }
        .chart-how-to {
            text-transform: none;
            text-decoration: underline;
        }
        .chart-how-to:hover {
            cursor: pointer;
        }
    }
    .chart-title {
        font-size: 8px;
        color: #48494B;
        padding-left: 10px;
        padding-right: 10px;
    }
    .wave-chart-unit {
        font-size: 8px;
        color: #1A237E;
        font-weight: bold;
    }
    .period-chart-unit {
        color: #2196F3;
        font-size: 8px;
        font-weight: bold;
    }
}