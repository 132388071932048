.wind-direction-label,
.wind-speed {
    font-size: 15px;
    font-weight: bold;
    height: 25px !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.wind-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
