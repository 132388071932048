.location-text{
    color: #D4D4D4 !important;
    padding-left: 10px;
    font-size: 14px;
    width: 100%;
    text-transform: none;
    font-weight: 500;
    line-height: 1.0;
    padding-right: 5px;
}

.location-name {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 16px;
}

// .location-name img {
//     margin: 0;
//     padding: 0;
//     vertical-align: middle; /* Aligns the image with the text */
// }

.location-link {
    color:#D4D4D4 !important;
}
.location-link:hover {
    color:#D4D4D4;
}
.location-spacer {
    height: 0.5px;
    background-color: #524E52;
    margin-top: 24.25px;
    margin-bottom: 24.25px
}
.location-text, .close-button, .good-surf-logo-container:hover{
    cursor: pointer;
}

.navbar-scroll{ 
    overflow-y: scroll !important;
    height: 100%;
    position: absolute;
    left: 0;
    top: 63px;
    background-color: #191819;
    overflow-x: hidden;
    padding-bottom: 60px;
}
.navbar-close {
    padding: 10px 5px;
    height: 63px;
}
.forecast-image {
    padding-top: 40px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.card-columns {
    display: flex;
    flex-direction: column;
    padding-top:20px;
    padding-bottom:20px;
    padding-left: 5px;
    padding-right: 5px;
    align-items: flex-start;
    // flex-wrap: wrap;
    justify-content: flex-start;
    overflow-x: hidden;
 }

 .section-location-list{
    background-color:#191819;
}

.coast-icons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 180px;
    width: 180px;
}

.good-surf-logo-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 180px;
    width: 180px;
}

.good-surf-logo-container-nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 150px;
    width: 150px;
}
.logo-col {
    height: 200px ;
}

.footer-image {
    height: 150px;
    width: 150px;
}

.location-gap {
    // padding-bottom: 50px;
}

.banner-image {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.footer-banner-left {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 40px;
}
.footer-banner-bottom {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 40px;
    padding-left: 5px;
    padding-right: 5px;
}
.footer-banner-desc {
    padding-left: 20px;
    padding-right: 20px;
    a {
        color: #fd2dbf;
    }
    .banner-desc-text {
        color: #FFFF;
        font-size: 14px;
        text-transform: none;
        font-weight: 500;
        line-height: 1.4;
    }
}

.coast-label {
    display: flex;
    flex-direction: row;
    justify-content: left;
    .coast-label-img {
        padding-left: 30px;
    }
}

.member-cams-title {
    font-weight: 600;
    margin-top: 50px;
    margin-left: 20px;
    margin-bottom: 0px;
    color: white;
    font-size: 18px;
    font-style: italic;
}