.recent-locations-container {
  padding-top: 63px;
  height: 110px !important;
  //   min-width: 1300px !important;
  overflow-x: scroll !important;
  display: flex;
  flex-direction: row;
  justify-content: left;
  background-color: #fafafa;
  padding-left: 30px;
  padding-right: 30px;
}
.recent-locations-container :first-child {
  padding-left: 0 !important;
  // text-wrap: nowrap;
}
.recent-locations-container :last-child {
  padding-right: 0 !important;
  // text-wrap: nowrap;
}
.recent-locations-container-empty {
  margin-top: 63px;
}
.recent-location {
  color: black !important;
  padding-left: 50px;
  padding-right: 10px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-wrap: nowrap;
}

.recent-location:hover {
  cursor: pointer;
}
.recent-location-text {
  padding-left: 5px;
  text-overflow: ellipsis;
  text-wrap: nowrap !important;
  width: 100%;
  flex-wrap: nowrap;
  white-space: nowrap;
}
