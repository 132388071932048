.post-page-header {
    margin-top: 63px;
}

.related-posts {
    padding: 10px;
    margin: 10px;
}

.post-label {
    font-size: 26px;
    color: black;
    font-weight: bold;
    p {
        font-weight: 500;
    }   
}

.gsn-share-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.gsn-share-button {
    padding-left: 5px !important;
}