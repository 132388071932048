.packages-title {
  // padding-top: 20px;
  padding-bottom: 0px;
  font-size: 26px;
  color: #fd2dbf;
  font-weight: bold;
  p {
    font-weight: 500;
  }
  text-align: center;
}

.plans {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.plan {
  background-color: #f9f9f9;
  // width: 48%;
  box-sizing: content-box;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  transition: all 0.3s ease-in-out;
  /* Add stronger box shadow */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  /* Subtle shadow */

  &:hover {
    /* Intensify the shadow on hover for a more dramatic effect */
    // box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
    // transform: translateY(-5px);  /* Optional: slight lift on hover */
    cursor: pointer;
  }
}

.plan:hover {
  cursor: pointer;
}
.plan-yearly {
  background-color: #f9f9f9;
  border: 4px solid #fd2dbf;
}

// .plan-plan-og {
//   background-color: #f9f9f9;
//   border: 4px solid #0000;
// }

.plan-monthly {
  background-color: #f9f9f9;
  border: 4px solid #878787ff;
}

.plan h3 {
  font-size: 24px;
  color: #333;
  margin-bottom: 0px;
  font-weight: 600;
  text-align: center;
}

.free-trial-text {
  font-size: 12px !important;
  // color: lightgray !important;
  margin-bottom: 5px;
  font-weight: 600;
  text-align: center;
}

.plan p {
  font-size: 16px;
  color: #666;
  margin-bottom: 5px;
}

.plan button {
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

.plan button:hover {
  background-color: #555;
}

.skip-now-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // margin-top: 55px;
}

.skip-now-text {
  color: gray;
  font-weight: 500;
}
.skip-now-text:hover {
  cursor: pointer;
}

.packages-description {
  text-align: center;
  font-weight: 550;

  padding-left: 10px;
  padding-right: 10px;
}

.yearly-promotion-container {
  background-color: #fd2dbf;
  color: white;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.monthly-promotion-container {
  background-color: transparent;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  // background-color: light;
}

.save-value {
  font-weight: 600;
}

.package-price-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.package-price-button {
  font-size: 18px;
  padding: 10px 20px;
  background-color: #fd2dbf;
  color: white;
  font-weight: 600;
}

.billing-cycle {
  color: black;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.auto-renewal {
  color: gray;
  text-align: center;
  font-size: 14px;
  margin-bottom: 20px;
  font-weight: 500;
}

.packages-fixed-height {
  height: 396.91px !important;
}

.og-promotion-container {
  // background-color: #c3996cff;
  background-color: black;
  color: white;
  height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.gsn-premium-image {
  border-radius:0% !important;
  transition: none !important;
  box-shadow: none !important;
}

.og-hookup-big {
  color: #c3996cff;
  font-size: 24px;
  font-weight: bold;
}

.be-first-text {
  font-weight: bold;
  font-size: 12px;
}

.fifty-percent {
  font-size: 24px;
}

.life-forty {
  font-size: 24px;
  color: white;
}

.use-code {
  color: #c3996cff;
  font-weight: bold;
}

.premium-highlights {
  font-weight: bold;
}

.premium-prices {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.premium-price-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.premium-cross {
  padding-right: 5px;
  text-decoration: line-through;
  // font-weight: 500;
}

.tnc-apply {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 12px;
}

.cancel-anytime {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  font-size: 10px;
  font-weight: bold;
  color: gray;
}

.highlights-list {
  position: relative;
  padding-left: 10px;
  // font-weight: bold;

}

.highlight-text {
  display: flex;
  flex-direction: row;
}

.highlight-tick {
  padding-right: 10px;
}