.live-cam-mask-text-container {
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0px;
  bottom: 0px;
  opacity: 0.8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.live-cam-mask-text {
  color: white;
  font-weight: bold;

  font-style: italic;
  text-align: center;
}

.live-cam-mask-links {
  color: #fd2dbf;
  font-style: normal;
  text-transform: capitalize;
}

.live-cam-mask-links:hover {
  cursor: pointer;
}

.walrus-lock-image {
  // width: 250px !important;
  height: 30% !important;
  width: 30% !important;
  transition: none !important;
  box-shadow: none !important;
  border-radius: 0% !important;
}

.walrus-join-button {
  color: black;
  background-color: transparent;
  padding: 5px 2px;
  border: none;
  font-weight: bold;
  text-transform: capitalize;
  cursor: pointer;
  font-size: 16px;
}

.walrus-join-button:hover {
  cursor: pointer;
  background-color: transparent;
}

.walrus-join-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.walrus-member-text {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.walrus-login-button {
  color: #fd2dbf !important;
  background-color: transparent;
  padding: 5px 2px;
  border: none;
  font-weight: bold;
  text-transform: capitalize;
  cursor: pointer;
  font-size: 18px;
  text-decoration: underline;
}

.walrus-login-button:hover {
  cursor: pointer;
  background-color: transparent;
}