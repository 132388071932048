.free-user-mask {
  background-color: gray;
  // height: 330px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.gsn-cam-thumbnail-icon {
  position: absolute !important;
  left: 15px;
  top: 15px;
}

.cam-location-image {
  width: 100%;
  box-sizing: border-box;
  box-shadow: none !important;
  border-radius: 0px !important;
}

