.chart-tooltip {
    width: 200px;
    font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
    .tooltip-timestamp {
        font-size: small;
        white-space:nowrap;
    }
    
    .tooltip-wind {
        color: #FD2DBF;
        font-size: small;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        white-space:nowrap;
    }
    
    .tooltip-wave {
        color: #1A237E;
        font-size: small;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        white-space:nowrap;
    }

    .tooltip-wave8s {
        color: #2196F3;
        font-size: small;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        white-space:nowrap;
    }
}
