.location-details {
  box-sizing: border-box;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 10px;
  padding-right: 10px;
  // display: flex;
  img {
    box-shadow: none !important;
    border-radius: 0px;
  }
  img:hover {
    transform: none !important;
  }
  .location-details-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    .location-image {
      width: 100%;
      box-sizing: border-box;
      padding-left: 10px;
      padding-right: 10px;
      // padding-bottom: 20px;
    }
    .location-info {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      box-sizing: border-box;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 8px !important;
      // padding-bottom: 20px;

      .location-description {
        width: 100%;
        overflow: hidden;
      }
    }
  }
}

.location-title {
  width: 100%;
  // height: 100px !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 12px;
  padding-right: 10px;
  .location-label {
    font-size: 26px;
    color: black;
    font-weight: bold;
  }
}

.location-img {
  box-shadow: none !important;
  border-radius: 0px !important;
}
.location-img:hover {
  transform: none !important;
}
.other-posts {
  padding: 10px;
  margin: 10px;
}
.location-header-less-padding {
  padding-top: 12px !important;
}
.location-page-banner {
  width: 100%;
  height: 100%;
}
.location-page-banner:hover {
  cursor: pointer;
}
.location-banner-img {
  width: 100%;
  height: 100%;
  border-bottom: 1px solid;
  border-color: darkgray;
}
.location-ad-center {
  // margin-top: auto !important;
  margin-bottom: auto !important;
}

.premium-video-container {
  margin: 0px;
}

.location-video-cam-container {
  background-color: black;
}
